import { usePage } from "@inertiajs/vue3";
import { App } from "vue";

type reverseArgs = string | number | object;
export type reverseFunction = (...args: reverseArgs[]) => string;

const routes = import.meta.glob("../routes/*.js", {
    eager: true,
    import: "default",
});

const reverse = (...args: reverseArgs[]) => {
    const lang = usePage().props?.language;
    const reverse = routes[`../routes/${lang}.js`] as reverseFunction;
    return reverse(...args);
};

const routeReverse = {
    install: (app: App) => {
        app.config.globalProperties.$reverse = reverse;
    },
};

export { reverse };
export default routeReverse;
